/* eslint-disable no-useless-escape */

// API BASE URL
export const API_URL = 'https://productionluxurycrm.beyonderissolutions.com';
// export const STORAGE_URL = 'https://d1ws2nmm1yk911.cloudfront.net/media/';
export const SOCKET_URL = 'wss://productionluxurycrm.beyonderissolutions.com';
export const STORAGE_URL =
  'https://lux-crm-storage-prod.s3.amazonaws.com/media/';
// HR role: options for settings page.

export const ROWS_PER_PAGE = '20';

export const EMAIL_REGEX =
  /^(([^`~!$%^&*?/|}{#=<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// /^[^<>()[\]\\,;:\%#^\s@\"$&!@\*\+]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

// HR ROLES
export const HR_CONTROLLER = 'HR Controller'; // 'Hr Controller';
export const HR_MANAGER = 'HR Manager'; // 'hr_manager';
export const HR_STAFF = 'HR Staff'; // 'hr_staff';

// ACCOUNTING ROLES
export const ACCOUNTING_CONTROLLER = 'Accounting Controller'; // 'accounting_controller';
export const ACCOUNTING_MANAGER = 'Accounting Manager'; // 'accounting_manager';
export const ACCOUNTING_STAFF = 'Accounting Staff'; // 'accounting_staff';
export const ACCOUNTING_STAFF_RECEIVABLE = 'Accounting Staff Receivable'; // 'accounting_staff_receivable';
export const ACCOUNTING_STAFF_PAYABLE = 'Accounting Staff Payable'; // 'accounting_staff_payable';

// OPERATIONS ROLES
export const OPERATIONS_CONTROLLER = 'Operations Controller'; // 'operations_controller';
export const OPERATIONS_MANAGER = 'Operations Manager'; //  'operations_manager';
export const OPERATIONS_STAFF = 'Operations Staff'; // 'operations_staff';

// SUPER USER
export const SUPER_USER = 'superuser';

// Departments
export const FINANCE_DEPT = 'Finance'; // finance
export const HR_DEPT = 'HR';
export const SALES_DEPT = 'Sales'; // 'sales';
export const OPERTIONS_DEPT = 'Operations';
export const OPERTIONS_VIP_DEPT = 'Operations VIP'; // 'op-vip';
export const OPERTIONS_CORP_DEPT = 'Operations Corporate'; // op-corp
export const OPERTIONS_MICE_DEPT = 'Operations Mice'; // op-mice
export const OPERTIONS_TICKETING_DEPT = 'Operations Ticketing'; // op-ticketing
// Operations VIP

// luxury explorer main company
export const LUXURY_COMPANY = 'Luxury Events and VIP Travel DMCC';
export const DEFAULT_PAGINATION_DATA = {
  count: 0,
  results: [],
  previous: null,
  next: null,
};

export const IMAGE_PLACEHOLDER = '/img/image-placeholder.png';
